<template>
    <div class="mb-4">
        <label v-if="props.label" class="form-label">{{ props.label }}</label>
        <p v-if="text" class="form-text" v-html="text"></p>
        <VueMultiselect
            :options="state.users"
            label="full_name"
            v-model="state.selectedUsers"
            placeholder="Select a user (type to search)"
            :select-label="null"
            :deselect-label="null"
            :show-no-results="true"
            :internal-search="true"
            :multiple="true"
            track-by="id"
        >
        <template #noResult="prop">
            No users found for <em>{{ prop.search }}</em>.
        </template>
        <template #tag="prop">
            <span class="multiselect__tag">
                <span>{{ prop.option.full_name }}</span>
                <i tabindex="1" class="multiselect__tag-icon" @click="removeUser(prop.option)"></i>
            </span>
        </template>
        <template #option="prop">
            {{ prop.option.full_name }}
        </template>
        </VueMultiselect>
    </div>
</template>

<script setup lang="ts">
const props = defineProps<{
    includeInactive?: Boolean,
    label?: string,
    selected?: number[];
    text?: string;
}>();

interface IState {
    users: Array<Object>;
    selectedUsers: Array<IUser>;
}

const state = reactive<IState>({
    users: [],
    selectedUsers: [],
});

const emit = defineEmits<{
    (event: 'update', value: IUser[]): void,
}>();

let url = '/api/users';
if (! props.includeInactive) {
    url = url+'?active=1';
}

const { data, error } = await useApiFetch(url);

if (data.value) {
    state.users = data.value.users;
}

if (props.selected) {
    state.selectedUsers = state.users.filter((user: IUser) => {
        return props.selected.indexOf(user.id) !== -1;
    });
}

watch(() => state.selectedUsers, (users: Array<IUser>) => {
    emit('update', users);
}, { deep: true });

const removeUser = (user: IUser) => {
    state.selectedUsers.splice(state.selectedUsers.indexOf(user), 1);
}
</script>
